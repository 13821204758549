import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ siteTitle }) => {
  return (
    <header className="static top-0 text-sm font-bold text-center uppercase border-b border-gray-300">
      <div className="flex justify-between bg-white justify-items-center">
        <div className="w-32 h-6 px-2 py-2">
          <Link to="/">
            <StaticImage
              src="../../images/logo/logo.png"
              layout="fullWidth"
              quality={95}
              // aspectRatio={0.4}              
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="maxhel.fr"
            />
          </Link>
        </div>
        <div className="flex justify-between divide-x divide-gray-300 justify-items-center">
          <div className="flex h-auto px-4 py-6">
            <span className="py-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-5 h-5 leading-10 text-gray-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>
            </span>
            <span className="px-2 text-sm font-medium leading-9 ">
              +33 777 68 03 54
            </span>
          </div>
          <div className="flex-auto h-auto px-4 py-6">
            <button className="px-4 py-2 font-semibold bg-gray-800 rounded-sm">
              <Link to="/gallery" className="text-white">
                Notre Galerie
              </Link>
            </button>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
